import Vue from 'vue'
import Vuex from 'vuex'
import { getSetting } from '@/api/setting'
Vue.use(Vuex)

export default new Vuex.Store({
  // 相当于组件中的data
  state: {
    requestApi: 'https://user.yuanaiconsulting.com/api',
    downLoadUrl: 'https://user.yuanaiconsulting.com',
    settingsInfo: {}, // 后台配置信息,
    comment: '结果声明：本测试结果只反映受测者当时的状态，内容仅供参考，如有疑问，请拨打源爱心理咨询热线或咨询专业心理咨询师', // 报告结果声明文字
  },
  // 类似于computed属性
  getters:{

  },
  // 同步操作，唯一修改state中数据的方式
  mutations: {
    changeInfo(state,payload) {
      state.settingsInfo = payload;
    }
  },
  // 里面都是异步请求
  actions: {
    async getInfo(context, payload) {
      const res = await getSetting();
      console.log(res);
      sessionStorage.setItem('banner', res.data.bannerLogo);
      context.commit('changeInfo', res.data);
    }
  },
  // 模块化
  modules: {
  }
})
